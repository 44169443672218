<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="position-relative overflow-hidden bg-img-hero bg-light lego-bg">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/solutions/api-management"> -->
                  <router-link :to="{ path: '/solutions/api-education' }">
                    API Education
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">LEGO® SERIOUS PLAY® Workshop</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Hero Title -->
      <div class="container position-relative z-index-2 space-top-1 space-bottom-5 text-center">
        <span class="d-block small font-weight-bold text-cap mb-2">API Education</span>
        <h1>LEGO® SERIOUS PLAY® Workshop</h1>
        <p>Build a Better API Product and Team With LEGO® SERIOUS PLAY® Method by beNovelty</p>
      </div>
      <!-- <img class="img-fluid" src="@/assets/img/education/lsp/lego-bg.jpg"> -->

      <!-- SVG Background -->
      <figure class="position-absolute right-0 bottom-0 left-0">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Intro Questions Section ========== -->
    <div class="container position-relative z-index-2">
      <div class="card mt-n11 mx-auto" data-aos="fade-up">
        <div class="card-body py-9">
          <h2 class="w-md-75 w-xl-60 mx-auto text-center mb-6">Have You Ever Encountered Challenges in API Design and Team Formation?</h2>
          <div class="row px-lg-5">
            <div class="col-6 col-md mb-5 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-misalignment.svg" alt="Sub-millisecond performance">
                </figure>
                <h6>Misalignment between product vision and team understanding</h6>
              </div>
            </div>
            <div class="col-6 col-md mb-5 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-miscommunication.svg" alt="Sub-millisecond performance">
                </figure>
                <h6>Inadequate communication and collaboration within the team</h6>
              </div>
            </div>
            <div class="col-6 col-md mb-5 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-team-knowledge-diff.svg" alt="Sub-millisecond performance">
                </figure>
                <h6>Difficulty in leveraging team knowledge</h6>
              </div>
            </div>
            <div class="col-6 col-md mb-5 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-lack-creativity.svg" alt="Sub-millisecond performance">
                </figure>
                <h6>Limited creativity in problem-solving</h6>
              </div>
            </div>
            <div class="col-12 col-md mb-5 mb-md-0">
              <div class="text-center">
                <figure class="max-w-10rem mx-auto mb-4">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-requirement.svg" alt="Sub-millisecond performance">
                </figure>
                <h6>Insufficient understanding of user needs and requirements</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Intro Questions Section ========== -->

    <!-- ========== What is LSP Section ========== -->
    <div class="container space-2 space-lg-3">
      <div class="row align-items-lg-center">
        <div class="col-md-6 order-md-1 mb-4 mb-md-0" data-aos="fade">
          <div class="position-relative h-100 w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/kong-building.svg">
          </div>
        </div>
        <div class="col-md-6">
          <span class="d-block small font-weight-bold text-cap mb-2">LEGO® Is More Than a Game</span>
          <h2>What is LEGO® SERIOUS PLAY®?</h2>
          <p>LEGO® SERIOUS PLAY® (LSP) is a facilitated workshop technique that utilizes Lego bricks as a medium for communication, problem-solving, and idea generation. It is based on the belief that everyone possesses valuable knowledge and insights, and that hands-on, visual activities can unlock creativity and enhance collaboration.</p>
        </div>
      </div>
    </div>
    <!-- ========== End What is LSP Section ========== -->

    <!-- ========== LSP Methodology Section ========== -->
    <div class="bg-light rounded" :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-20.svg') + ')'}">
      <div class="container space-1 space-md-2">
        <!-- Title -->
        <div class="w-lg-80 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">Behind the Methodology</span>
          <h2>How does LEGO® SERIOUS PLAY® work?</h2>
        </div>

        <!-- Methodology -->
        <div class="row justify-content-between align-items-center mb-9 mb-md-0">
          <div class="col-lg-6">
            <div
              id="featuresSliderThumb"
              class="js-slick-carousel slick slick-pagination-grid slick-transform-off mx-auto py-4 py-md-3"
              data-hs-slick-carousel-options="{
                        &quot;infinite&quot;: true,
                        &quot;centerMode&quot;: true,
                        &quot;slidesToShow&quot;: 4,
                        &quot;isThumbs&quot;: true,
                        &quot;asNavFor&quot;: &quot;#featuresSliderNav&quot;
                        }"
            >
              <div class="w-25 w-lg-50 border">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block mb-4">Crafted with Specific Bricks</h4>
                      <figure class="w-65 w-md-65 w-lg-30 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-29.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50 border">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block mb-4">Spark Creativity with Metaphor</h4>
                      <figure class="w-65 w-md-65 w-lg-30 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-23.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50 border">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block mb-4">Adopt Hands-On Minds-On Learning Approach</h4>
                      <figure class="w-65 w-md-65 w-lg-30 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-customer.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-25 w-lg-50 border">
                <div class="js-slide text-center">
                  <div class="card card-bg-white h-100 shadow-none overflow-hidden rounded-0">
                    <div class="card-body p-3 p-lg-4">
                      <h4 class="d-none d-lg-block mb-4">Encourage Storytelling & Sharing</h4>
                      <figure class="w-65 w-md-65 w-lg-30 mx-auto mb-0 mb-lg-3">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-7.svg">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              id="featuresSliderNav"
              class="js-slick-carousel slick"
              data-hs-slick-carousel-options="{
                    &quot;infinite&quot;: true,
                    &quot;autoplay&quot;: true,
                    &quot;autoplaySpeed&quot;: 8000,
                    &quot;dots&quot;: true,
                    &quot;dotsClass&quot;: &quot;slick-pagination d-flex justify-content-center d-md-none mt-3&quot;,
                    &quot;fade&quot;: true,
                    &quot;asNavFor&quot;: &quot;#featuresSliderThumb&quot;,
                    &quot;prevArrow&quot;: &quot;<span class=\&quot;fas fa-arrow-left slick-arrow slick-arrow-primary slick-arrow-left slick-arrow-centered-y rounded-circle ml-sm-n2 d-none d-md-block\&quot;></span>&quot;,
                    &quot;nextArrow&quot;: &quot;<span class=\&quot;fas fa-arrow-right slick-arrow slick-arrow-primary slick-arrow-right slick-arrow-centered-y rounded-circle mr-sm-n2 d-none d-md-block\&quot;></span>&quot;
                    }"
            >

              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h4 class="text-center mb-3">Crafted with Specific Bricks</h4>
                <figure class="w-30 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-29.svg">
                </figure>
                <p>LSP workshops are custom-designed without assembly instructions. Our facilitators create unique roadmaps based on the workshop's purpose, utilizing specially selected LEGO® bricks for engaging activities.</p>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h4 class="text-center mb-3">Spark Creativity with Metaphor</h4>
                <figure class="w-30 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-23.svg">
                </figure>
                <p>The generation of ideas thrives on associative thinking, allows participants construct 3D models as metaphors for related problems. This process fosters deep understanding, stimulates creativity, and enables effective problem-solving.</p>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h4 class="text-center mb-3">Adopt Hands-On Minds-On Learning Approach</h4>
                <figure class="w-30 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-customer.svg">
                </figure>
                <p>LSP prioritizes "hands-on" model building, where participants swiftly assemble bricks within a time constraint. This dynamic building process emphasizes action, fostering new neural connections between the hand and the brain, fueling creativity and imagination.</p>
              </div>
              <div class="js-slide py-1 py-md-5 px-1 px-md-7">
                <h4 class="text-center mb-3">Ecourage Storytelling & Sharing</h4>
                <figure class="w-30 mx-auto mb-3">
                  <img class="img-fluid" src="@/assets/svg/icons/icon-7.svg">
                </figure>
                <p>Once participants construct their LEGO® brick models, they are encouraged to share the story behind their creations. Through skillful implementation of the LSP method, facilitators prompt thought-provoking questions, enabling participants to delve deeper and offer richer insights about their models.</p>
              </div>
            </div>
          </div>
        </div>

        <!-- LSP can help to solve... -->
        <div class="overflow-hidden">
          <div class="container space-top-2 space-top-lg-3">
            <!-- Title -->
            <div class="w-lg-80 text-center mx-md-auto mb-7">
              <span class="d-block small font-weight-bold text-cap mb-2">The Advantages</span>
              <h2>Applying LSP to API Product Design and Team Formation</h2>
              <p>The LEGO® SERIOUS PLAY® method creates a participatory and inclusive environment where team members actively contribute. By utilizing hands-on construction with Lego bricks and following the API product design steps, it enhances collaboration and communication of complex concepts, leading to improved outcomes.</p>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-6 order-lg-2 mb-5 mb-lg-0">
                <div class="position-relative h-100 w-80 mx-auto">
                  <img class="img-fluid" src="@/assets/img/education/lsp/lego-1.jpg">
                </div>
              </div>
              <div class="col-lg-6 order-lg-1">
                <p>By leveraging the LEGO® SERIOUS PLAY® method, participants can accomplish the following:</p>
                <ul class="step step-dashed mb-0">
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="fas fa-check" /></span>
                      <div class="step-content">
                        <p>Facilitate vision alignment</p>
                      </div>
                    </div>
                  </li>
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="fas fa-check" /></span>
                      <div class="step-content">
                        <p>Enhance communication with storytelling</p>
                      </div>
                    </div>
                  </li>
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="fas fa-check" /></span>
                      <div class="step-content">
                        <p>Tap into team intelligence for diverse perspectives</p>
                      </div>
                    </div>
                  </li>
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="fas fa-check" /></span>
                      <div class="step-content">
                        <p>Encourage creative problem-solving</p>
                      </div>
                    </div>
                  </li>
                  <li class="step-item">
                    <div class="step-content-wrapper">
                      <span class="step-icon step-icon-xs step-icon-soft-primary"><i class="fas fa-check" /></span>
                      <div class="step-content">
                        <p>Gain user insights through prototyping and scenarios</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End LSP Methodology Section ========== -->

    <!-- ========== beNovelty LSP Workshop Section ========== -->
    <div class="position-relative bg-img-hero">
      <div class="container space-top-2">
        <!-- Title -->
        <div class="row align-items-lg-center mb-9">
          <div class="col-md-6">
            <span class="d-block small font-weight-bold text-cap mb-2">What We Offer</span>
            <h2>LEGO® SERIOUS PLAY® Workshop by beNovelty</h2>
            <p>beNovelty adopts LEGO® SERIOUS PLAY® (LSP) methods for API product ideation and enhancement in both internal and external projects, aiming to draw diverse ideas and opinions.</p>
          </div>
          <div class="col-md-6">
            <div class="position-relative h-100 w-80 mx-auto">
              <img class="img-fluid" src="@/assets/img/education/lsp/lego-2.jpg">
            </div>
          </div>
        </div>

        <!-- Details -->
        <div class="bg-light" :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-20.svg') + ')'}">
          <div class="space-1 space-md-2 px-4 px-md-8 px-lg-10">
            <div class="overflow-hidden">

              <!-- Our Approach -->
              <div class="mb-11">
                <div class="text-center mb-5">
                  <h3>Our Approach</h3>
                </div>
                <div class="row justify-content-center">
                  <div class="col-12 col-sm-6 col-md-4 px-2 mb-3 mb-md-0">
                    <div class="card h-100">
                      <img class="card-img-top" src="@/assets/img/education/lsp/lsp-workshop-step-1.jpg">
                      <div class="card-body">
                        <h4>Empathize & Define</h4>
                        <p>Understand the persona and define the problems</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 px-2 mb-3 mb-md-0">
                    <div class="card h-100">
                      <img class="card-img-top" src="@/assets/img/education/lsp/lsp-workshop-step-2.jpg">
                      <div class="card-body">
                        <h4>Ideate & Prototype</h4>
                        <p>Generate ideas and rapid prototyping with LEGO bricks</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 col-md-4 px-2">
                    <div class="card h-100">
                      <img class="card-img-top" src="@/assets/img/education/lsp/lsp-workshop-step-3.jpg">
                      <div class="card-body">
                        <h4>Test & Feedback</h4>
                        <p>Validate and test the ideas via discussions led by our API experts and compile action lists</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Our Qualifications -->
              <div class="mb-11">
                <div class="row align-items-center">
                  <div class="col-lg-6 mb-5 mb-lg-0">
                    <div class="mb-5">
                      <h3>Our Qualifications</h3>
                      <p>Our Workshop is led by facilitation Team with:</p>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Certified facilitator of LEGO® SERIOUS PLAY® method
                      </div>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Certified UX Specialists
                      </div>
                    </div>
                    <div class="media">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Seasoned API Product Manager
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <img class="img-fluid" src="@/assets/img/education/lsp/lego-4.jpg">
                  </div>

                </div>
              </div>

              <!-- Our Workshops -->
              <div class="mb-11">
                <div class="row align-items-center">
                  <div class="col-lg-6 mb-5 mb-lg-0 order-lg-2">
                    <div class="mb-5">
                      <h3>Our Workshops</h3>
                      <p>Our LSP workshops are fully customized and can be applied to different topics.</p>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        API Product Innovations
                      </div>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Unlock Creativity
                      </div>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Personal Growth
                      </div>
                    </div>
                    <div class="media mb-4">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Team Building & Development
                      </div>
                    </div>
                    <div class="media">
                      <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                        <i class="fas fa-check" />
                      </span>
                      <div class="media-body text-dark">
                        Complex Problem Solving
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 order-lg-1">
                    <img class="img-fluid" src="@/assets/img/education/lsp/lego-3.jpg">
                  </div>
                </div>
              </div>

              <!-- Types of Workshop -->
              <div class="mb-0">
                <div class="text-center mb-5">
                  <h3>Types of Workshop</h3>
                  <p>We provide two types of workshops tailored to meet your needs and requirements.</p>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 px-2 mb-3 mb-md-0">
                    <!-- Card -->
                    <div class="card h-100">
                      <img class="card-img-top" src="@/assets/img/education/lsp/lego-5.jpg">
                      <div class="card-body">
                        <h4>Half Day Workshop</h4>
                        <ul>
                          <li>2.5 Hours</li>
                          <li>You will have the opportunity to select a challenge to solve during the design thinking workshop.</li>
                        </ul>
                      </div>
                    </div>
                  <!-- End Card -->
                  </div>

                  <div class="col-12 col-sm-6 px-2">
                    <!-- Card -->
                    <div class="card h-100">
                      <img class="card-img-top" src="@/assets/img/education/lsp/lego-6.jpg">
                      <div class="card-body">
                        <h4>Full Day Workshop</h4>
                        <ul>
                          <li>5 Hours</li>
                          <li>The creativity workshop ignites participants' creative thinking through a hypothetical challenge statement, introducing design thinking and fostering creativity.</li>
                        </ul>
                      </div>
                    </div>
                  <!-- End Card -->
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- ========== End beNovelty LSP Workshop Section ========== -->

    <!-- ========== Contact Section ==========  -->
    <div class="container space-2">
      <div class="w-lg-80 text-center mx-lg-auto">
        <h2 class="mb-3">Want to Know More?</h2>
        <p>Please feel free to reach out to us if you are interested in enrolling in our upcoming LEGO® SERIOUS PLAY® Workshop for either a public class or arranging a private class for your internal team. We would be thrilled to discuss the details with you and accommodate your specific needs.</p>
      </div>
      <div class="w-md-60 mx-auto">
        <div class="card">
          <div class="card-body">
            <form ref="benGeneralForm" />
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Contact Section ==========  -->

    <!-- ========== More Solutions Section ==========-->
    <div
      class="space-2 bg-blue-light"
      :style="{'background-image': 'url(' + require('@/assets/svg/components/abstract-shapes-9-1-01.svg') + ')',
               backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}"
    >
      <div class="w-md-80 w-lg-50 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">More by beNovelty</span>
        <h2>Want to Know More About API Economy?</h2>
      </div>
      <div class="position-relative">
        <div class="container">
          <div
            class="js-slick-carousel slick slick-equal-height slick-gutters-3"
            data-hs-slick-carousel-options="{
                &quot;prevArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-left slick-arrow slick-arrow-primary-white slick-arrow-left slick-arrow-centered-y shadow-soft rounded-circle ml-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;nextArrow&quot;: &quot;<span class=\&quot;fa fa-arrow-right slick-arrow slick-arrow-primary-white slick-arrow-right slick-arrow-centered-y shadow-soft rounded-circle mr-sm-n2 d-none d-lg-inline\&quot;></span>&quot;,
                &quot;infinite&quot;: true,
                &quot;dots&quot;: true,
                &quot;dotsClass&quot;: &quot;slick-pagination d-lg-none&quot;,
                &quot;autoplay&quot;: true,
                &quot;autoplaySpeed&quot;: 5000,
                &quot;slidesToShow&quot;: 3,
                &quot;responsive&quot;: [{
                  &quot;breakpoint&quot;: 1200,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 3
                    }
                  }, {
                  &quot;breakpoint&quot;: 992,
                    &quot;settings&quot;: {
                      &quot;slidesToShow&quot;: 2
                    }
                  }, {
                  &quot;breakpoint&quot;: 768,
                  &quot;settings&quot;: {
                    &quot;slidesToShow&quot;: 1
                  }
                  }]
              }"
          >
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-4.svg">
                  <h4>API Consultancy</h4>
                  <p>Read more to find out what makes beNovelty different in API consultancy.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-consultancy-button" href="/solutions/api-consultancy"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-consultancy-button" :to="{ path: '/solutions/api-consultancy' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-28.svg">
                  <h4>API Product</h4>
                  <p>Find out the 3 simple steps for building API-as-a-Products.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-product-button" href="/solutions/api-product"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-product-button" :to="{ path: '/solutions/api-product' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-3.svg">
                  <h4>API Management</h4>
                  <p>Learn how to manage, secure, mediate, analyze complex enterprise API programme with API Management.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-management-button" href="/solutions/api-management"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-management-button" :to="{ path: '/solutions/api-management' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
            <div class="js-slide">
              <div class="card my-5">
                <div class="card-body pb-0">
                  <img class="img-fluid max-w-8rem mb-4" src="@/assets/svg/icons/icon-69.svg">
                  <h4>API Community</h4>
                  <p>Discover more on our latest API community events.</p>
                </div>
                <div class="card-footer pt-0 border-0 bg-transparent">
                  <!-- <a id="ga-solutions-more-by-benovelty-api-community-button" href="/solutions/api-community"> -->
                  <router-link id="ga-solutions-more-by-benovelty-api-community-button" :to="{ path: '/solutions/api-community' }">
                    Learn More <i class="fas fa-angle-right fa-sm ml-1" />
                  </router-link>
                  <!-- </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Solutions Section ========== -->

  </main>
</template>

<script>
import 'bootstrap'
const $ = require('jquery')
// import ContactUsBar from '@/components/contact-us-bar'
import HSGoTo from '../../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../../assets/js/hs.core.js'
import '../../../../assets/js/hs.slick-carousel.js'
import '../../../../assets/vendor/slick-carousel/slick/slick.js'
import 'bootstrap'
import { mapGetters } from 'vuex'
export default {
  name: 'LegoSeriousPlay',
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'partners'
    ])
  },
  async mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    $('.js-slick-carousel').each(function() {
      $.HSCore.components.HSSlickCarousel.init($(this))
    })
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  methods: {
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'LEGO® SERIOUS PLAY® Workshop | API Education | Solutions | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/solutions/api-education/lego-serious-play' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'LEGO® SERIOUS PLAY® Workshop | API Education | Solutions | beNovelty' },
        { property: 'og:description', content: 'Lego Serious Play (LSP) is a facilitated workshop technique that utilizes Lego bricks as a medium for communication, problem-solving, and idea generation. beNovelty adopts LEGO® SERIOUS PLAY® (LSP) methods for API product ideation and enhancement in both internal and external projects, aiming to draw diverse ideas and opinions.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/solutions/api-education/lego-serious-play' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../../assets/vendor/slick-carousel/slick/slick.css");
@import url("../../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");

.lego-bg {
  background-image: url('../../../../assets/img/education/lsp/lego-bg.jpg')
}

@media (min-width: 567px) {
  .lego-bg {
    background-image: url('../../../../assets/img/education/lsp/lego-bg-md.jpg');
  }
}

@media (min-width: 1600px) {
  .lego-bg {
    background-image: url('../../../../assets/img/education/lsp/lego-bg-xl.jpg');
  }
}
</style>
